/*
    SaSS compilation is handled by webpack via a top-level `npm run build`
    or `npm run watch` command.

    To compile this SaSS into CSS without using webpack, do the following:
        cd /app/static/css
        sass --watch sass/main.scss:main.min.css --style compressed
*/
/* VENDORS - Default fall-backs and external files.
========================================================================== */
/* UTILS - Tool, setting, and configuration files.
========================================================================== */
/* BASE - Boilerplate files.
========================================================================== */
html {
  position: relative; }

html, body {
  min-height: 100%;
  width: 100%; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background: #f5f5f5; }

#root {
  display: -webkit-flex !important;
  display: flex !important;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100%; }
  #root > div {
    min-height: 100%;
    width: 100%;
    padding-bottom: 60px; }

.text-gray {
  color: #888; }

.text-axgen {
  color: #62C3D0; }

.back-link {
  margin-bottom: 0.25em;
  color: #62C3D0; }

a:hover, a:active, a:focus {
  text-decoration: none; }

.bg {
  background-image: url(/assets/imgs/dna-bg.jpg);
  background-size: cover; }

.bg2 {
  background-image: url(/assets/imgs/osteostrong.jpeg);
  background-size: contain; }

.apexcharts-menu-icon {
  display: none; }

.__react_component_tooltip.type-dark.tooltip-axgen {
  background-color: #62C3D0;
  max-width: 300px;
  opacity: 0.95; }
  .__react_component_tooltip.type-dark.tooltip-axgen.place-top:after {
    border-top-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-axgen.place-bottom:after {
    border-bottom-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-axgen.place-right:after {
    border-right-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-axgen.place-left:after {
    border-left-color: #62C3D0; }

.__react_component_tooltip.type-dark.tooltip-categ {
  background-color: #6cbfee;
  max-width: 500px;
  opacity: 0.95; }
  .__react_component_tooltip.type-dark.tooltip-categ.place-top:after {
    border-top-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-categ.place-bottom:after {
    border-bottom-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-categ.place-right:after {
    border-right-color: #62C3D0; }
  .__react_component_tooltip.type-dark.tooltip-categ.place-left:after {
    border-left-color: #62C3D0; }

.img-wrap {
  float: right;
  padding-left: 1em; }

.athlete-icon {
  display: inline-block;
  text-align: center; }
  .athlete-icon > div {
    border: 1px solid rgba(98, 195, 208, 0.25);
    border-radius: 4px; }
  .athlete-icon img {
    width: 80%; }
  .athlete-icon p {
    color: #62C3D0;
    font-weight: bold; }

.clipboard-copy {
  cursor: pointer; }
  .clipboard-copy:hover {
    background-color: rgba(98, 195, 208, 0.3); }
  .clipboard-copy:active {
    background-color: #62C3D0; }

/* LAYOUTS - Structure, section, and layout files.
========================================================================== */
.card-hover {
  transition: transform .2s; }
  .card-hover:hover {
    transform: scale(1.05); }
  .card-hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: opacity .2s ease-in-out; }
  .card-hover:hover::after {
    opacity: 1; }

.result-card, .card {
  position: relative;
  -webkit-box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  border: 1px solid rgba(150, 150, 150, 0.1);
  border-radius: 6px;
  padding: 3em 2em 1em 2em;
  background: #fff; }

.orders-parent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 0 1em; }
  .orders-parent .left {
    margin-right: 1em;
    width: 100%;
    height: 100%; }
  .orders-parent .right {
    margin-left: 1em;
    width: 100%;
    height: 100%; }

.order-card {
  position: relative;
  -webkit-box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  border: 1px solid rgba(150, 150, 150, 0.1);
  border-radius: 6px;
  padding: 3em 2em 1em 2em;
  background: #fff; }

.dashboard-item .result-card {
  padding: 1.5rem 1rem; }
  .dashboard-item .result-card.gray {
    color: #888; }
    .dashboard-item .result-card.gray .result-icon {
      -webkit-filter: grayscale(90%);
      filter: grayscale(90%); }
  .dashboard-item .result-card.gray-full {
    background-color: #ddd;
    color: #666; }
    .dashboard-item .result-card.gray-full .result-value {
      color: #777; }
    .dashboard-item .result-card.gray-full .result-icon {
      -webkit-filter: grayscale(90%);
      filter: grayscale(90%); }
  .dashboard-item .result-card.green .result-icon {
    -webkit-filter: hue-rotate(300deg);
    filter: hue-rotate(300deg); }
  .dashboard-item .result-card.green .result-value {
    color: #2ecc71; }
  .dashboard-item .result-card.red .result-icon {
    -webkit-filter: hue-rotate(150deg);
    filter: hue-rotate(150deg); }
  .dashboard-item .result-card.red .result-value {
    color: #e74c3c; }
  .dashboard-item .result-card.blue .result-icon {
    -webkit-filter: hue-rotate(340deg);
    filter: hue-rotate(340deg); }
  .dashboard-item .result-card.blue .result-value {
    color: #53cac4; }
  .dashboard-item .result-card img {
    height: 125px; }
  .dashboard-item .result-card .card-body {
    padding: 1.25rem 0; }
  .dashboard-item .result-card .result-name {
    display: block; }
  .dashboard-item .result-card .result-value {
    color: #aaa; }
  .dashboard-item .result-card .result-icon {
    margin-bottom: 0.5rem;
    border: 2px dashed #62C3D0;
    border-radius: 50%;
    height: 120px;
    padding: 0.5rem; }
  .dashboard-item .result-card .result-text {
    padding-top: 0.5rem; }

.result-card-link {
  color: inherit; }
  .result-card-link:hover, .result-card-link:active, .result-card-link:focus {
    color: inherit;
    text-decoration: inherit; }

.form-img-container {
  float: right;
  padding-left: 2em; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .form-img-container {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .form-img-container .form-img-wrap {
      width: 100%;
      height: auto; } }

@media screen and (max-width: 767px) {
  .card {
    padding: 1.2em; }
  .dashboard-item .result-card {
    padding: 1.2em; }
    .dashboard-item .result-card .result-name {
      font-size: 14px; }
    .dashboard-item .result-card .result-value {
      font-size: 14px; }
    .dashboard-item .result-card .result-icon {
      height: 104px; }
  .orders-parent {
    display: flex;
    flex-direction: column; }
    .orders-parent .right {
      margin-left: 0;
      margin-top: 2em; }
  .card-body h3 {
    font-size: 1.4em; }
  .form-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 0; }
    .form-img-container .form-img-wrap {
      width: 100%;
      height: auto; }
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; }
    .img-container img {
      width: 20em;
      height: auto;
      padding-right: 2em;
      padding-left: 0; } }

@media screen and (max-width: 319px) {
  .dashboard-item .result-card .result-icon {
    height: 82px; }
  .dashboard-item .result-card .result-name, .dashboard-item .result-card .result-value {
    font-size: 12px; } }

.dashboard-item.disabled .card {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.1); }

.dashboard-item.active a {
  color: #62C3D0; }

.dashboard-item .card {
  padding: 2em; }

.dashboard-item .card-body {
  padding: 1.25rem 0; }

.dashboard-item a {
  color: #484848;
  text-decoration: none; }

.dashboard-item h3 {
  font-size: 1.4em; }

.waitlist-counter {
  margin-bottom: -1.5em; }

.icon-23andme, .icon-ancestry, .icon-axgen-kit, .icon-uploads, .icon-axgen {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 6em; }

.icon-axgen {
  background-image: url(/assets/imgs/axgen-logo.png); }

.icon-uploads {
  background-image: url(/assets/imgs/upload-logos.png); }

.icon-23andme {
  background-image: url(/assets/imgs/23andme-logo.png); }

.icon-ancestry {
  background-image: url(/assets/imgs/ancestry-logo.png); }

.icon-axgen-kit {
  background-image: url(/assets/imgs/axgen-kit2.png); }

@media screen and (max-width: 767px) {
  .dashboard-item {
    font-size: 0.6em; }
    .dashboard-item .card {
      padding: 1.2em 1em; }
      .dashboard-item .card .card-body {
        padding: 1em 0; }
        .dashboard-item .card .card-body .item-subtitle {
          font-size: 1.1em; } }

.form input.form-control, .form select.form-control {
  margin-bottom: 1.5em; }

.checkbox-lg {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2); }

.flex-form {
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 300px;
  padding: 0em 2em 2em 2em;
  background: #fafafa; }
  .flex-form.auth-form {
    padding: 2em; }
  .flex-form input.form-control {
    margin-bottom: 1.5em; }

.file-upload .card-container {
  border: 2px dashed rgba(80, 80, 80, 0.3);
  border-radius: 16px;
  padding: 4em 1em;
  text-align: center;
  color: rgba(80, 80, 80, 0.5);
  cursor: pointer; }
  .file-upload .card-container.active {
    border-color: #62C3D0;
    color: #62C3D0; }
  .file-upload .card-container.failed {
    border-color: #d44950;
    color: #d44950; }

.download-instructions li {
  padding: 0.75em 0; }

.download-instructions .break-word {
  word-break: break-word; }

.order-option {
  cursor: pointer; }
  .order-option .card-body div {
    font-size: 1.5em; }
  .order-option.active {
    border: 1px solid #62C3D0;
    box-shadow: rgba(98, 195, 208, 0.5) -1px 2px 5px 0px;
    background-color: rgba(98, 195, 208, 0.15); }

.order-option-disabled .card-body div {
  font-size: 1.5em; }

.bulk-option i {
  font-size: 5em;
  color: #63c3d0; }

.email-list-field {
  min-height: 2em;
  border: 1px solid rgba(80, 80, 80, 0.3);
  border-radius: 6px;
  padding: 1em; }
  .email-list-field li {
    width: 100%;
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
    padding: 0.25em;
    border-radius: 6px; }
    .email-list-field li p {
      width: 80%;
      overflow: hidden;
      margin-bottom: 0; }
    .email-list-field li .icon-container {
      width: 20%;
      text-align: center; }
      .email-list-field li .icon-container i {
        font-size: 1.2em; }
  .email-list-field li:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04); }
  .email-list-field li:last-child {
    margin-bottom: 0; }

@media screen and (max-width: 767px) {
  .flex-form {
    margin-bottom: 10%; }
    .flex-form.auth-form {
      padding: 2em 2em 0em 2em; }
  .buy-header h2 {
    text-align: center; }
  .order-option, .order-option-disabled {
    padding: 1em; }
    .order-option .card-body, .order-option-disabled .card-body {
      padding: 1em 0;
      font-size: 1em; }
  .mobile-title {
    text-align: center; }
  .bulk-option i {
    font-size: 3.5em; }
  .bulk-option h3 {
    font-size: 0.5em; } }

@media screen and (max-width: 319px) {
  .order-option .card-body div, .order-option-disabled .card-body div {
    font-size: 18px; } }

/* MODULES - Re-usable site elements.
========================================================================== */
.bog-graph-tooltip {
  border-radius: 4px; }
  .bog-graph-tooltip .tooltip-header {
    border-radius: 4px 4px 0 0;
    background-color: #ecf0f1; }
  .bog-graph-tooltip div {
    padding: 0.25em 1em;
    border: 1px solid #ecf0f1; }

.btn-axgen {
  border-color: #62C3D0;
  background-color: rgba(98, 195, 208, 0.85);
  color: #fff; }
  .btn-axgen:hover, .btn-axgen:focus, .btn-axgen.active {
    background-color: #62C3D0;
    color: #fff; }
  .btn-axgen:disabled {
    cursor: not-allowed;
    border-color: #868686;
    background-color: #8a8a8a; }

.btn-outline-axgen {
  color: #62C3D0;
  border-color: #62C3D0; }
  .btn-outline-axgen:hover, .btn-outline-axgen:focus, .btn-outline-axgen.active {
    color: #fff;
    background-color: #62C3D0; }

.btn-link {
  color: #62C3D0; }

.btn-gray {
  border-color: #b7b7b7;
  background-color: #bbbbbb;
  color: #fff; }
  .btn-gray:hover, .btn-gray:focus, .btn-gray.active {
    border-color: #868686;
    background-color: #8a8a8a;
    color: #fff; }

.faq .faq-header {
  list-style: none;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: flex-start;
  margin-bottom: 0;
  padding-left: 0; }

.faq .faq-toggle {
  margin: 0 0.5em;
  transition: transform .5s ease-in-out; }

.faq .faq-body {
  display: block;
  margin-left: 2em;
  max-height: 99em;
  padding: 0.5em 1em;
  overflow: hidden;
  transition: max-height .5s ease-in-out, padding .5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.05); }

.faq.collapsed .faq-toggle {
  transform: rotate(-90deg); }

.faq.collapsed .faq-body {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), padding 0.5s ease-in-out; }

@media screen and (max-width: 767px) {
  .faq-header {
    margin-bottom: 0.5em; } }

.app-footer {
  text-align: center;
  bottom: 0;
  position: absolute;
  width: 100%;
  min-height: 60px;
  padding: 16px; }
  .app-footer .footer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .app-footer .footer-content .footer-top {
      width: 100%;
      color: #212529; }
    .app-footer .footer-content ul {
      width: 100%;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 0;
      padding-left: 0; }
      .app-footer .footer-content ul li {
        padding: 0 20px; }
        .app-footer .footer-content ul li a {
          text-decoration: none;
          color: #212529; }
        .app-footer .footer-content ul li a:hover, .app-footer .footer-content ul li a:focus {
          text-decoration: underline;
          cursor: pointer; }
    .app-footer .footer-content .footer-top-force-white {
      color: #fafafa; }
    .app-footer .footer-content .footer-ul-force-white li a {
      color: #fafafa; }

@media screen and (max-width: 767px) {
  .app-footer .footer-content .footer-top, .app-footer .footer-content .footer-top-force-white {
    font-size: 14px; }
  .app-footer .footer-content ul li {
    padding: 0 10px; }
    .app-footer .footer-content ul li a {
      font-size: 14px; }
  .app-footer .footer-content .footer-ul-force-white li a {
    color: #fafafa; } }

@media screen and (max-width: 319px) {
  .app-footer .footer-content .footer-top, .app-footer .footer-content .footer-top-force-white, .app-footer .footer-content ul li a {
    font-size: 12.5px; } }

.gene-table th, .gene-table td, .article-table th, .article-table td {
  padding: 0.5em 0.5em; }

.gene-table tr:hover td, .article-table tr:hover td {
  background-color: #f6f6f6; }

.gene-table tbody tr, .article-table tbody tr {
  border-top: 1px solid #ddd;
  background: #fff; }
  .gene-table tbody tr td, .article-table tbody tr td {
    padding: 1em 0.5em; }

.gene-table th:hover {
  color: #62C3D0; }

.permissions-table td, .permissions-table th {
  padding: 1em 0.5em; }

.permissions-table th {
  text-align: center; }

.title-break {
  margin-bottom: 1em;
  border-bottom: 1px solid #bbb;
  width: 100%;
  text-align: center;
  height: 1.32em; }
  .title-break > span {
    padding: 0 10px;
    font-size: 1.5em;
    background-color: #f5f5f5;
    color: #555; }

@media screen and (max-width: 767px) {
  .title-break {
    margin-bottom: 2em;
    height: 17.5px; }
    .title-break > span {
      font-size: 21px;
      padding: 0; } }

@media screen and (max-width: 319px) {
  .landing-items-parent .title-break {
    margin-bottom: 2em;
    height: 13.5px; }
    .landing-items-parent .title-break > span {
      font-size: 17px;
      padding: 0; } }

button.MUIDataTableToolbar-icon-38:hover,
button.MUIDataTableToolbar-iconActive-39 {
  color: #62C3D0; }

fieldset svg.MuiSvgIcon-root-99 {
  fill: #62C3D0; }

div.MuiInputBase-formControl-188:after {
  border-bottom: 2px solid #62C3D0; }

.navbar {
  -webkit-box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  border: 1px solid rgba(150, 150, 150, 0.1);
  background: #fff; }
  .navbar .container {
    padding-left: 15px;
    padding-right: 15px; }
  .navbar .navbar-brand a {
    text-decoration: none; }
  .navbar .navbar-brand .logo {
    height: 2.5em;
    cursor: pointer; }
  .navbar .navbar-brand .logo-text {
    position: relative;
    top: 2px;
    color: #484848; }
  .navbar .nav-item {
    display: inline-block; }
    .navbar .nav-item .nav-link {
      color: #62C3D0;
      padding: .8rem .8rem; }
    .navbar .nav-item .nav-link:hover {
      cursor: pointer; }
    .navbar .nav-item .nav-link.active {
      border-bottom: 1.5px solid #62C3D0;
      margin: 0 .8rem;
      padding: 0;
      color: #62C3D0;
      display: block;
      padding-bottom: 0.4rem; }
    .navbar .nav-item:hover .nav-link, .navbar .nav-item:active .nav-link {
      color: rgba(98, 195, 208, 0.6); }
  .navbar .nav-item.active {
    padding-top: 0.8rem; }
  .navbar.navbar-clean {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none; }
  .navbar .nav-user-icon {
    padding: 0.5em;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #006e79;
    color: #fff;
    cursor: pointer; }
  .navbar .dropdown {
    padding-left: 1rem; }
    .navbar .dropdown .dropdown-toggle::after {
      display: none; }
    .navbar .dropdown .dropdown-menu {
      padding: 0; }
      .navbar .dropdown .dropdown-menu .dropdown-item {
        border-bottom: 1px solid #eee;
        padding: 0.75em 0.75em; }
        .navbar .dropdown .dropdown-menu .dropdown-item:hover {
          background-color: #006e79;
          color: #fff; }
        .navbar .dropdown .dropdown-menu .dropdown-item:first-child {
          border-radius: 0.25em 0.25em 0 0; }
        .navbar .dropdown .dropdown-menu .dropdown-item:last-child {
          border-radius: 0 0 0.25em 0.25em;
          border-bottom: 0; }
      .navbar .dropdown .dropdown-menu .dropdown-item.active {
        background-color: #006e79; }

.header-logo {
  height: 40px; }

.app-banner {
  width: 100%;
  position: relative;
  background: #026d78;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #cfced4;
  padding: 0.5em 0;
  display: flex;
  list-style: none;
  flex-direction: column;
  margin-bottom: 0;
  font-size: 16px;
  color: #fff;
  justify-content: center;
  align-items: center;
  height: 36px; }
  .app-banner p {
    margin-bottom: 0; }

.app-banner:hover {
  cursor: pointer; }
  .app-banner:hover p {
    text-decoration: underline;
    color: #fff; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar .dropdown .menu-bars:hover {
    color: rgba(98, 195, 208, 0.6);
    cursor: pointer; }
  .navbar .dropdown .dropdown-menu .dropdown-item:first-child {
    display: flex;
    justify-content: center; }
    .navbar .dropdown .dropdown-menu .dropdown-item:first-child span:hover {
      cursor: default; }
  .navbar .dropdown .dropdown-menu .dropdown-item:first-child:hover {
    background: #fff;
    cursor: default;
    color: #484848; }
  .dropdown {
    color: #484848; }
  .app-banner {
    font-size: 13.5px; } }

@media screen and (max-width: 767px) {
  .navbar-nav {
    width: 100%;
    justify-content: center; }
  .navbar .dropdown .menu-bars:active {
    color: rgba(98, 195, 208, 0.6);
    cursor: pointer; }
  .navbar .dropdown .dropdown-menu .dropdown-item {
    font-size: .95em;
    padding: 0.5rem 0.75rem; }
  .navbar .dropdown .dropdown-menu .dropdown-item:first-child {
    display: flex;
    justify-content: center; }
    .navbar .dropdown .dropdown-menu .dropdown-item:first-child span:hover {
      cursor: default; }
  .navbar .dropdown .dropdown-menu .dropdown-item:first-child:hover {
    background: #fff;
    cursor: default;
    color: #484848; }
  .dropdown {
    color: #484848; }
  .app-banner {
    font-size: 12px;
    height: 68px; } }

.progress {
  border: 1px solid #ccc;
  background: #fff;
  margin-bottom: 2em;
  height: 1em; }

.progress-bar {
  background: #62C3D0;
  transition: 1s ease;
  transition-delay: 0.5s; }

.progress-header {
  margin: 0 auto 2.5em auto;
  font-size: 2em; }

.progress-step {
  position: relative;
  display: inline-block; }
  .progress-step .progress-step-content {
    padding: 1em 0.5em 1.5em 0.5em;
    text-align: center; }
  .progress-step .progress-step-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 50%; }
    .progress-step .progress-step-icon.complete {
      background: #62C3D0;
      border: 2px solid #62C3D0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .progress-step .progress-step-icon.complete .fa, .progress-step .progress-step-icon.complete .fas {
        color: #fff;
        font-size: 14px; }
    .progress-step .progress-step-icon.active, .progress-step .progress-step-icon.error {
      background: #62C3D0;
      height: 2.5em;
      width: 2.5em;
      border: 2px solid #62C3D0;
      bottom: -26px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .progress-step .progress-step-icon.active .fa, .progress-step .progress-step-icon.active .fas, .progress-step .progress-step-icon.error .fa, .progress-step .progress-step-icon.error .fas {
        color: #fff; }
    .progress-step .progress-step-icon.error {
      background: #d44950;
      border: 2px solid #d44950; }
    .progress-step .progress-step-icon.pending {
      border: 2px solid #ccc;
      padding-top: 0.4em;
      background: #fff;
      color: #ccc; }
    .progress-step .progress-step-icon.complete, .progress-step .progress-step-icon.pending {
      height: 1.5em;
      width: 1.5em;
      bottom: -18px; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .progress-step-content .progress-text {
    font-size: 14px; }
  .progress-error-text p {
    font-size: 14.5px; } }

@media screen and (max-width: 767px) {
  .progress-step .progress-step-content {
    padding-top: 0; }
    .progress-step .progress-step-content .progress-text {
      font-size: 11.5px; }
  .progress-error-text p {
    font-size: 12px; } }

.stripe-icon {
  display: block;
  margin: 2em auto;
  height: 2em; }

.stripe-ele {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1em; }

.sale-banner {
  background: #7ecdd7; }

.sale-text {
  color: #fff;
  text-align: center;
  padding-top: 0.2em; }

.sale-ends {
  text-align: center;
  color: #fff; }

.team-table table {
  overflow-x: auto; }
  .team-table table th:first-child, .team-table table tbody tr td:nth-child(2) {
    padding-left: 1.5em; }
  .team-table table th:last-child {
    padding-left: 1.5em; }
  .team-table table th:nth-child(n+2), .team-table table tbody tr td:nth-child(n+4) {
    text-align: center; }
  .team-table table th, .team-table table td {
    padding: 1em 0.5em; }
  .team-table table td:first-child {
    padding-left: 1.5em; }
  .team-table table td:last-child {
    padding-right: 1.5em; }

.toggle {
  min-height: 38px;
  cursor: pointer; }
  .toggle .btn span {
    margin: 0 auto; }

.toggle-handle {
  background: #fff; }

.Collapsible {
  background-color: #5ea3d1; }
  .Collapsible.elite-video {
    background-color: #195d68; }
  .Collapsible.prime-video {
    background-color: #138c99; }
  .Collapsible.foundation-video {
    background-color: #84e2ee; }
  .Collapsible.elite-video .Collapsible__trigger {
    background-color: #063138; }
  .Collapsible.prime-video .Collapsible__trigger {
    background-color: #006d78; }
  .Collapsible.foundation-video .Collapsible__trigger {
    background-color: #62c3d0; }
  .Collapsible.foundation-video .Collapsible__trigger:hover, .Collapsible.prime-video .Collapsible__trigger:hover, .Collapsible.elite-video .Collapsible__trigger:hover {
    background-color: #0087bd; }

.Collapsible span .collapsible-trigger {
  width: 100%; }

.Collapsible span:hover {
  cursor: pointer;
  background: #5da3d1; }
  .Collapsible span:hover .collapsible-trigger span {
    background-color: transparent; }

.Collapsible__contentInner {
  padding: 10px;
  border: 1px solid white;
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: gray;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #3382c2;
  color: white; }
  .Collapsible__trigger:after {
    font-family: 'Font Awesome 5 Pro';
    content: '\f107';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms; }
  .Collapsible__trigger.is-open:after {
    transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.intro-vid {
  display: flex;
  justify-content: center;
  align-items: center; }
  .intro-vid iframe {
    border-radius: 6px; }

@media screen and (max-width: 767px) {
  .intro-vid iframe {
    width: 100%;
    height: auto; }
    .intro-vid iframe img {
      width: 100%; } }

.table-container {
  position: relative;
  -webkit-box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  border: 1px solid rgba(150, 150, 150, 0.1);
  border-radius: 6px;
  padding: 1em 2em;
  background: #fff;
  width: 100%;
  margin-bottom: 3em; }

.table-subcontainer {
  width: 100%; }

.default-table {
  width: 100%;
  border: 1px solid #ddd; }
  .default-table caption {
    padding-bottom: 0; }
  .default-table th, .default-table td {
    padding: 0.5em 0.5em; }
  .default-table thead {
    border-bottom: 3px solid #ddd; }
  .default-table tr:hover td {
    background-color: #f6f6f6; }
  .default-table tbody tr {
    border-top: 1px solid #ddd;
    background: #fff; }
    .default-table tbody tr:nth-child(2n) {
      background-color: #f9f9f9; }

.table-line {
  margin: 1.5em 0; }

@media screen and (max-width: 767px) {
  .table-container {
    overflow: auto; }
  .table-subcontainer {
    overflow: auto; }
  .default-table {
    border-radius: 6px;
    font-size: 13px; }
    .default-table tbody {
      border: 1px solid #ddd;
      display: block; }
    .default-table th, .default-table td {
      padding: 0.5em 0.5em;
      width: 110px; }
    .default-table thead {
      border-bottom: 1px solid #ddd; }
    .default-table caption {
      padding: 1em 2em 1em 0; }
  .default-table thead tr {
    display: block;
    border: 1px solid #ddd; } }

/* PAGES - Page specific files.
========================================================================== */
.result-container {
  -webkit-box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1em;
  padding: 3em 2em 1em 2em;
  margin-bottom: 2em;
  background: #fafafa; }

.result-explorer {
  padding: 0; }
  .result-explorer > .row {
    margin-left: 0;
    margin-right: 0; }
  .result-explorer .content-view {
    width: 100%; }
  .result-explorer .card {
    padding: 1em 2em 1em 2em;
    margin-bottom: 3em;
    display: block;
    overflow: auto; }
    .result-explorer .card p .exercise-span-datatip {
      color: #007bff; }
    .result-explorer .card p .exercise-span-datatip:hover {
      color: #0056b3;
      cursor: pointer; }
  .result-explorer .title-margins {
    text-align: center;
    margin: 0em 0 2em 0; }

.bg-img .title-margins {
  margin: 0;
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  padding-top: 160px; }

.sticky-top-banner, .sticky-top-bannerless {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  z-index: 1019; }

.sticky-top-banner {
  top: 86px; }

.sticky-top-bannerless {
  top: 50px; }

.result-nav {
  box-shadow: rgba(125, 125, 125, 0.2) -1px 2px 5px 0px;
  border-bottom: 1px solid rgba(150, 150, 150, 0.1); }
  .result-nav .nav-title {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding: 1em 0; }
    .result-nav .nav-title .result-intro-header .back-link {
      font-size: 18px;
      position: absolute;
      left: 0;
      font-weight: 300;
      color: #555; }
      .result-nav .nav-title .result-intro-header .back-link:hover, .result-nav .nav-title .result-intro-header .back-link:active, .result-nav .nav-title .result-intro-header .back-link:focus {
        color: #62C3D0; }
    .result-nav .nav-title .result-intro-header .title-risk {
      border-radius: 6px;
      font-size: 18px;
      padding: 0.25em 0.5em; }
      .result-nav .nav-title .result-intro-header .title-risk.red {
        background-color: rgba(231, 76, 60, 0.2);
        color: #e74c3c; }
      .result-nav .nav-title .result-intro-header .title-risk.green {
        background-color: rgba(46, 204, 113, 0.2);
        color: #2ecc71; }
      .result-nav .nav-title .result-intro-header .title-risk.gray {
        background-color: rgba(136, 136, 136, 0.2);
        color: #888; }
      .result-nav .nav-title .result-intro-header .title-risk.gray-full {
        background-color: rgba(102, 102, 102, 0.2);
        color: #666; }
      .result-nav .nav-title .result-intro-header .title-risk.blue {
        background-color: rgba(83, 202, 196, 0.2);
        color: #53cac4; }
    .result-nav .nav-title .nav-risk {
      margin: 0 0.5em;
      border-radius: 6px;
      padding: 0.25em 0.5em;
      font-weight: 400;
      font-size: 0.85em; }
      .result-nav .nav-title .nav-risk.red {
        background-color: rgba(231, 76, 60, 0.2);
        color: #e74c3c; }
      .result-nav .nav-title .nav-risk.green {
        background-color: rgba(46, 204, 113, 0.2);
        color: #2ecc71; }
      .result-nav .nav-title .nav-risk.gray {
        background-color: rgba(136, 136, 136, 0.2);
        color: #888; }
      .result-nav .nav-title .nav-risk.gray-full {
        background-color: rgba(102, 102, 102, 0.2);
        color: #666; }
      .result-nav .nav-title .nav-risk.blue {
        background-color: rgba(83, 202, 196, 0.2);
        color: #53cac4; }
  .result-nav .nav-items, .result-nav .nav-items-cramped {
    border-top: 1px solid #eee;
    text-align: center;
    display: flex;
    justify-content: center; }
    .result-nav .nav-items .nav-item, .result-nav .nav-items-cramped .nav-item {
      display: inline-block;
      padding: 1em;
      margin: 0 0.5em;
      border-bottom: 3px solid transparent;
      color: #484848;
      text-decoration: none;
      font-size: 16px; }
      .result-nav .nav-items .nav-item:hover, .result-nav .nav-items .nav-item:active, .result-nav .nav-items .nav-item:focus, .result-nav .nav-items .nav-item.active, .result-nav .nav-items-cramped .nav-item:hover, .result-nav .nav-items-cramped .nav-item:active, .result-nav .nav-items-cramped .nav-item:focus, .result-nav .nav-items-cramped .nav-item.active {
        color: #62C3D0;
        border-bottom: 3px solid #62C3D0; }

.result-sport-figure {
  text-align: center;
  color: #62C3D0;
  font-weight: bold;
  font-size: 1.2em; }
  .result-sport-figure img {
    display: block;
    height: 200px;
    margin: auto; }

.graph-comment {
  color: #b8b8b8;
  size: 6px;
  padding-top: 1em; }

.table-comment {
  color: #b8b8b8;
  size: 6px;
  padding-top: 1em; }

.histogram-container {
  margin: 0 auto; }
  .histogram-container.b12-hist .graph-comment {
    padding-top: 0; }

.apexcharts-axis-labels, .apexcharts-annotations, .apexcharts-tooltip {
  font-size: 14px; }

.apexcharts-axis-titles, .apexcharts-title-text {
  font-size: 16px; }

.graph-container {
  margin: 0 auto;
  border: 1px dashed #ccc;
  padding: 1em; }
  .graph-container.bmd-graph {
    max-width: 700px; }
    .graph-container.bmd-graph .apexcharts-xaxis, .graph-container.bmd-graph .apexcharts-yaxis {
      pointer-events: all; }
    .graph-container.bmd-graph .apexcharts-xaxis-texts-g {
      display: none; }

.high-risk-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }

.card .overview-img {
  float: right;
  margin-left: 0.5em; }

.risk-graph-container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .risk-graph-container:first-child {
    display: flex;
    justify-content: center; }

.card .card-image .left-img {
  float: left;
  margin-right: 1.5em;
  object-fit: contain;
  margin-bottom: 8px; }

.risk-graph-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .risk-graph-container:first-child {
    display: flex;
    justify-content: center; }

.hide {
  display: none; }

@media (min-width: 768px) and (max-width: 991px) {
  .card .overview-img {
    height: 200px; }
  .result-nav .nav-title .result-intro-header .back-link {
    font-size: 16px; }
  .result-nav .nav-title .result-intro-header .title-risk {
    font-size: 16px; }
  .result-nav .nav-items .nav-item, .result-nav .nav-items-cramped .nav-item {
    font-size: 14.2px; } }

@media screen and (max-width: 767px) {
  .result-nav .nav-title {
    padding-top: 0;
    font-size: 16px; }
    .result-nav .nav-title .nav-risk {
      margin: 0 5em; }
    .result-nav .nav-title .result-intro-header {
      margin-top: 0;
      display: flex;
      justify-content: center;
      flex-direction: column; }
      .result-nav .nav-title .result-intro-header .back-link {
        font-size: 15px;
        position: relative; }
      .result-nav .nav-title .result-intro-header .title-risk {
        font-size: 15px; }
  .result-nav .nav-items-cramped {
    overflow: scroll;
    display: flex;
    justify-content: flex-start; }
    .result-nav .nav-items-cramped .nav-item {
      font-size: 12px;
      padding: 1em 0.5em;
      margin: 0 0.25em; }
  .result-nav .nav-items {
    overflow: scroll;
    display: flex;
    justify-content: center; }
    .result-nav .nav-items .nav-item {
      font-size: 12px;
      padding: 1em 0.5em; }
  .results-parent-container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto; }
  .result-sport-figure {
    font-size: 1em; }
    .result-sport-figure img {
      height: 8em;
      width: 8em; }
  .result-explorer .card .overview-img {
    width: 100%;
    height: 130px;
    object-fit: contain;
    margin-bottom: 8px;
    margin-left: 0; }
  .histogram-container {
    padding-top: 1.5em;
    margin-right: -1em;
    margin-left: -1em; }
    .histogram-container.b12-hist .apexcharts-canvas {
      height: 330px !important; }
      .histogram-container.b12-hist .apexcharts-canvas svg {
        height: 100%; }
  .apexcharts-axis-titles, .apexcharts-title-text {
    font-size: 13.7px; }
  .apexcharts-axis-labels, .apexcharts-annotations, .apexcharts-tooltip {
    font-size: 12px; }
  .graph-container {
    padding-top: 4em;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -1em;
    margin-right: -1em;
    margin-left: -1em; }
  .graph-comment {
    font-size: 0.8em;
    padding-top: 2em; }
  .marker-container {
    margin-right: -1em;
    margin-left: -1em; }
  .sticky-top-banner {
    top: 118px; } }

@media screen and (max-width: 319px) {
  .result-nav .nav-title .result-intro-header .back-link {
    font-size: 14.5px; }
  .result-nav .nav-title .result-intro-header .title-risk {
    font-size: 14.5px; }
  .result-nav .nav-items .nav-item, .result-nav .nav-items-cramped .nav-item {
    font-size: 11px; }
  .result-sport-figure img {
    height: 7.5em;
    width: 7.5em; } }

.logo-title {
  position: relative;
  top: 8px;
  font-size: 2em;
  font-weight: bold;
  color: #484848; }

.welcome-logo-title {
  position: relative;
  top: 4px;
  font-size: 1.3em; }

.auth-text-section {
  width: 100%;
  margin-bottom: 1em; }
  .auth-text-section label {
    margin-bottom: 0; }
  .auth-text-section p {
    margin-bottom: 10px; }
  .auth-text-section .reg-qs-parent .reg-qs {
    list-style: none;
    padding-left: 0;
    margin-bottom: 10px; }
    .auth-text-section .reg-qs-parent .reg-qs li {
      padding: 0.5em;
      border: 3px solid black;
      border-radius: 6px;
      margin-top: 0.5em; }
    .auth-text-section .reg-qs-parent .reg-qs li:hover {
      cursor: pointer;
      background: #dedede; }
    .auth-text-section .reg-qs-parent .reg-qs .li-active {
      background: #dedede; }
  .auth-text-section .answers {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .auth-text-section .answers label {
      padding-right: 2em; }
  .auth-text-section .referrer-name {
    margin-top: 10px; }
    .auth-text-section .referrer-name input {
      margin-bottom: 0; }

.reg-footer-container {
  margin-top: 8em; }

.auth-form-parent {
  width: 450px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px 0px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-top: 2em;
  margin-bottom: 2em; }

.auth-form-container {
  background: #fafafa;
  padding: 35px 40px 20px 40px;
  border-radius: 4px 4px 0 0; }

.auth-form-header {
  text-align: center;
  margin-bottom: 15px; }
  .auth-form-header h1 {
    margin-bottom: 0;
    font-size: 32.5px; }
  .auth-form-header .logo-img {
    height: 65px; }

.auth-line {
  width: 100%;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px; }

.auth-form-bottom {
  padding: 10px 20px;
  background: #eceff1;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  border-top: 1px solid #ccc; }
  .auth-form-bottom .form-links {
    font-size: 15px; }
    .auth-form-bottom .form-links a {
      color: #62C3D0; }
    .auth-form-bottom .form-links a:hover {
      text-decoration: underline;
      color: #62C3D0; }

.axgen-auth-link {
  color: #62C3D0; }

.axgen-auth-link:hover {
  text-decoration: underline;
  color: #62C3D0; }

.logreg-form {
  width: 100%;
  transform: scaleY(1);
  transform-origin: top;
  visibility: visible;
  transition: 0.2s all ease-in-out; }
  .logreg-form .auth-form .auth-input-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    border-radius: 10px; }
    .logreg-form .auth-form .auth-input-container i {
      background: #006e79;
      color: white;
      min-width: 50px;
      text-align: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .logreg-form .auth-form .auth-input-container label {
      margin: 0;
      position: absolute;
      line-height: 1.5;
      color: #212529;
      transition: all 0.1s ease;
      top: 10px;
      left: 61px;
      z-index: 2; }
    .logreg-form .auth-form .auth-input-container label.active {
      font-size: .75rem;
      top: 3px; }
    .logreg-form .auth-form .auth-input-container input.active {
      padding: 20px 10px 5px 10px; }
    .logreg-form .auth-form .auth-input-container input:-webkit-autofill,
    .logreg-form .auth-form .auth-input-container input:-webkit-autofill:hover,
    .logreg-form .auth-form .auth-input-container input:-webkit-autofill:focus,
    .logreg-form .auth-form .auth-input-container input:-webkit-autofill:active {
      padding: 10px; }
    .logreg-form .auth-form .auth-input-container .form-control {
      width: 100%;
      padding: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 44px;
      z-index: 3;
      background-color: transparent; }
    .logreg-form .auth-form .auth-input-container .form-control:focus {
      border-color: #62C3D0;
      box-shadow: 0 0 0 0.2rem rgba(99, 195, 208, 0.2); }
  .logreg-form .auth-form .auth-input-container-iconless {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    position: relative; }
    .logreg-form .auth-form .auth-input-container-iconless i {
      padding: 10px;
      background: #006e79;
      color: white;
      min-width: 50px;
      text-align: center;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .logreg-form .auth-form .auth-input-container-iconless .form-control {
      width: 100%;
      padding: 10px;
      border-radius: 10px;
      height: 44px; }
    .logreg-form .auth-form .auth-input-container-iconless .form-control:focus {
      border-color: #62C3D0;
      box-shadow: 0 0 0 0.2rem rgba(99, 195, 208, 0.2); }
  .logreg-form .auth-form .form-links {
    margin-bottom: 10px;
    margin-top: 5px;
    text-align: left;
    font-size: 15px; }
    .logreg-form .auth-form .form-links a {
      color: #62C3D0; }
    .logreg-form .auth-form .form-links a:hover {
      text-decoration: underline;
      color: #62C3D0; }
  .logreg-form .auth-form .submit-row {
    margin: 20px 0 15px 0; }
    .logreg-form .auth-form .submit-row .reg-btn {
      width: 100%;
      height: 44px;
      border-radius: 10px;
      background-color: #63c3d0;
      color: #fff; }
    .logreg-form .auth-form .submit-row .reg-btn:disabled {
      cursor: not-allowed;
      border-color: #868686;
      background-color: #8a8a8a; }
    .logreg-form .auth-form .submit-row .reg-btn:hover {
      background-color: rgba(98, 195, 208, 0.8); }
  .logreg-form .auth-form .form-sentence {
    margin-bottom: 10px;
    margin-top: 2px;
    font-size: 14px;
    color: #8a8a8a; }

@media screen and (max-width: 767px) {
  .reg-footer-container {
    margin-top: 2em; }
  .auth-form-parent {
    border: none;
    box-shadow: none;
    margin: 2em 0.5em; }
    .auth-form-parent .logreg-form .auth-form-header h1 {
      font-size: 28.44px; }
    .auth-form-parent .logreg-form .auth-form-header .logo-img {
      height: 56.88px; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container label {
      font-size: 14px; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container label.active {
      font-size: .70rem; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container input {
      font-size: 14px;
      height: 38.5px; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container input.active {
      padding: 17.5px 10px 4.38px 10px; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container input:-webkit-autofill,
    .auth-form-parent .logreg-form .auth-form .auth-input-container input:-webkit-autofill:hover,
    .auth-form-parent .logreg-form .auth-form .auth-input-container input:-webkit-autofill:focus,
    .auth-form-parent .logreg-form .auth-form .auth-input-container input:-webkit-autofill:active {
      padding: 10px; }
    .auth-form-parent .logreg-form .auth-form .auth-input-container i {
      font-size: 15.75px; }
    .auth-form-parent .logreg-form .auth-form .form-links {
      font-size: 13.13px; }
    .auth-form-parent .logreg-form .auth-form .submit-row .reg-btn {
      height: 38.5px;
      font-size: 14px; }
    .auth-form-parent .logreg-form .auth-form .submit-row .reg-btn:disabled {
      cursor: not-allowed;
      border-color: #868686;
      background-color: #8a8a8a; }
    .auth-form-parent .logreg-form .auth-form .form-sentence {
      font-size: 12.25px; }
  .auth-form-container {
    padding: 40px 20px 20px 20px; }
  .auth-form-bottom {
    padding: 10px 20px; }
    .auth-form-bottom .form-links {
      font-size: 13.13px; }
  .auth-text-section p, .auth-text-section li, .auth-text-section label {
    font-size: 14px; }
  .auth-bottomQs {
    font-size: 14px; } }

@media screen and (max-width: 319px) {
  .auth-form-parent .logreg-form .auth-form .auth-input-container label {
    font-size: 12px; }
  .auth-form-parent .logreg-form .auth-form .auth-input-container input {
    font-size: 12px; } }

.multi-step-form-parent p {
  font-size: 14px;
  text-align: left;
  margin-bottom: 0; }

.multi-step-form-parent .our-consultants {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }
  .multi-step-form-parent .our-consultants li {
    display: flex;
    flex-direction: row;
    position: relative;
    padding: 1em;
    margin-bottom: 0.5em;
    border-radius: 6px;
    border: 1px solid #62C3D0;
    align-items: center; }
    .multi-step-form-parent .our-consultants li .avatar-container img {
      width: 175px;
      height: auto;
      display: block;
      border-radius: 100%; }
    .multi-step-form-parent .our-consultants li .consult-text {
      margin-left: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .multi-step-form-parent .our-consultants li .consult-text h5 {
        font-size: 19px;
        font-weight: 500; }
  .multi-step-form-parent .our-consultants li:hover {
    transition: transform .2s, -webkit-transform .2s;
    cursor: pointer;
    background-color: rgba(98, 195, 208, 0.15); }
  .multi-step-form-parent .our-consultants .consultant-li-active {
    background-color: rgba(98, 195, 208, 0.15);
    box-shadow: rgba(98, 195, 208, 0.5) -1px 2px 5px 0px;
    border: 1px solid #62C3D0; }

.consultation-form i {
  font-size: 6em;
  color: #63c2d3; }

.consultation-form .consult-form-section {
  margin-bottom: 1em;
  margin-top: 0.5em; }
  .consultation-form .consult-form-section .subsection-title {
    font-size: 1.1em; }
  .consultation-form .consult-form-section .order-option p, .consultation-form .consult-form-section .order-option-disabled p {
    text-align: left;
    font-size: 14px;
    margin-bottom: 0; }
  .consultation-form .consult-form-section .order-option .consult-price, .consultation-form .consult-form-section .order-option-disabled .consult-price {
    text-align: center;
    font-weight: 500;
    font-size: 15px; }
  .consultation-form .consult-form-section .order-option-disabled {
    background-color: rgba(138, 138, 138, 0.3);
    opacity: 0.5;
    cursor: not-allowed;
    position: relative; }
  .consultation-form .consult-form-section .order-option-disabled:before {
    content: "SOLD OUT";
    position: absolute;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    text-align: center;
    width: 97%;
    height: 150px;
    top: 40%;
    font-size: 1.5em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center; }

.consultation-form .multi-step-form-parent .consult-form-content {
  width: 100%;
  border-radius: 6px; }

.consultation-form .order-option-textbox {
  height: 16em; }

.consultation-form .consult-bottom {
  margin-top: 2em; }
  .consultation-form .consult-bottom .step-tracker {
    background-image: url(/assets/imgs/the-new-line.png);
    object-fit: contain;
    background-repeat: no-repeat;
    background-position-y: 30px;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px; }
    .consultation-form .consult-bottom .step-tracker li {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      z-index: 2; }
    .consultation-form .consult-bottom .step-tracker .step {
      font-size: 18px; }
      .consultation-form .consult-bottom .step-tracker .step .circle {
        width: 2em;
        height: 2em;
        border: 2px solid #63c2d3;
        background-color: #fff;
        border-radius: 50%; }
    .consultation-form .consult-bottom .step-tracker .step-active {
      font-size: 18px;
      font-weight: 700; }
      .consultation-form .consult-bottom .step-tracker .step-active .circle {
        width: 2em;
        height: 2em;
        border: 2px solid #63c2d3;
        background-color: #63c2d3;
        border-radius: 50%; }

.stripe-parent {
  width: 100%; }
  .stripe-parent .consult-stripe-form .consult-picker {
    width: 100%; }
    .stripe-parent .consult-stripe-form .consult-picker select {
      width: 30%;
      border-width: 2px; }

.calendly-form-parent {
  width: 100%; }
  .calendly-form-parent .calendly-embed-container {
    overflow: scroll; }
  .calendly-form-parent .calendly-inline-widget {
    min-width: 320px;
    height: 750px; }

.consult-show-parent .consult-datetime {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  .consult-show-parent .consult-datetime .calendar-icon {
    margin-right: 1em; }
    .consult-show-parent .consult-datetime .calendar-icon i {
      font-size: 10em;
      color: #35b749; }
  .consult-show-parent .consult-datetime .date-time-info {
    margin-left: 1em; }
    .consult-show-parent .consult-datetime .date-time-info p {
      margin-bottom: 0;
      font-size: 17px;
      padding: 0.5em 0; }

.consult-form-header {
  text-align: center; }

.credit-form .consult-card {
  margin-bottom: 0.5em;
  margin-top: 0.5em; }
  .credit-form .consult-card .selected-consultant {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 1em; }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .consultation-form i {
    font-size: 5em; }
  .consultation-form .consult-form-section .consult-option {
    width: 100%; }
  .consultation-form .consult-form-section .order-option p, .consultation-form .consult-form-section .order-option-disabled p {
    font-size: 13.5px; }
  .consultation-form .consult-form-section .order-option .card-body div, .consultation-form .consult-form-section .order-option-disabled .card-body div {
    font-size: 1.2em; }
  .consultation-form .consult-form-section .order-option .consult-price, .consultation-form .consult-form-section .order-option-disabled .consult-price {
    font-size: 14.5px; }
  .consultation-form .consult-bottom .step-tracker {
    background-position-y: 25px; }
    .consultation-form .consult-bottom .step-tracker .step {
      font-size: 14px; }
    .consultation-form .consult-bottom .step-tracker .step-active {
      font-size: 14px; }
  .consultation-form .multi-step-form-parent p {
    font-size: 13.5px; }
  .consultation-form .multi-step-form-parent .our-consultants {
    margin-top: 0; }
    .consultation-form .multi-step-form-parent .our-consultants li .consult-text h5 {
      font-size: 17px; }
    .consultation-form .multi-step-form-parent .our-consultants li .avatar-container img {
      width: 160px;
      height: auto; }
  .consultation-form .calendly-form-parent .calendly-inline-widget {
    height: 600px;
    margin-bottom: 1em; } }

@media only screen and (max-width: 767px) {
  .consultation-form i {
    font-size: 5em; }
  .consultation-form .consult-form-section .subsection-title {
    text-align: center; }
  .consultation-form .consult-form-section .order-option p, .consultation-form .consult-form-section .order-option-disabled p {
    font-size: 12px; }
  .consultation-form .consult-form-section .order-option .card-body div, .consultation-form .consult-form-section .order-option-disabled .card-body div {
    font-size: 1.1em; }
  .consultation-form .consult-form-section .order-option .consult-price, .consultation-form .consult-form-section .order-option-disabled .consult-price {
    font-size: 13px; }
  .consultation-form .consult-form-section .order-option-disabled:before {
    width: 89%; }
  .consultation-form .order-option-textbox {
    height: 14.5em; }
  .consultation-form .consult-bottom {
    margin-top: 2em; }
    .consultation-form .consult-bottom .step-tracker {
      background-position-y: 23px; }
      .consultation-form .consult-bottom .step-tracker .step {
        font-size: 13.5px; }
      .consultation-form .consult-bottom .step-tracker .step-active {
        font-size: 13.5px; }
  .consultation-form .multi-step-form-parent {
    margin-right: 0;
    margin-left: 0; }
    .consultation-form .multi-step-form-parent .consult-form-content {
      padding-left: 0;
      padding-right: 0;
      border-color: transparent; }
  .consultation-form .multi-step-form-parent p {
    font-size: 12px; }
  .consultation-form .multi-step-form-parent .our-consultants li {
    flex-direction: column; }
    .consultation-form .multi-step-form-parent .our-consultants li h4:after {
      width: 20%; }
    .consultation-form .multi-step-form-parent .our-consultants li .avatar-container {
      display: flex;
      justify-content: center;
      margin-top: 1em; }
      .consultation-form .multi-step-form-parent .our-consultants li .avatar-container img {
        width: 150px;
        height: auto;
        display: block;
        border-radius: 100%; }
    .consultation-form .multi-step-form-parent .our-consultants li .consult-text {
      margin-left: 0;
      margin-top: 1em; }
      .consultation-form .multi-step-form-parent .our-consultants li .consult-text h5 {
        font-size: 16px;
        text-align: center; }
  .stripe-parent {
    width: 100%; }
    .stripe-parent .consult-stripe-form .consult-picker {
      width: 100%; }
      .stripe-parent .consult-stripe-form .consult-picker select {
        width: 70%;
        border-width: 2px; }
  .calendly-form-parent .calendly-inline-widget {
    height: 700px;
    min-width: 250px;
    margin-bottom: 2em;
    overflow: hidden; }
  .consult-show-parent .consult-datetime {
    flex-direction: column;
    text-align: center; }
    .consult-show-parent .consult-datetime .calendar-icon {
      margin-right: 0;
      margin-top: 1em; }
      .consult-show-parent .consult-datetime .calendar-icon i {
        font-size: 8em;
        color: #35b749; }
    .consult-show-parent .consult-datetime .date-time-info {
      margin-left: 0;
      margin-top: 1em; }
      .consult-show-parent .consult-datetime .date-time-info p {
        margin-bottom: 0;
        font-size: 15px;
        padding: 0.5em 0; } }

@media only screen and (max-width: 319px) {
  .consultation-form .order-option-textbox {
    height: 18.5em; } }

.strength-card p a {
  text-decoration: none; }

.strength-card p a:hover {
  cursor: pointer;
  text-decoration: underline; }

.strength-pdf-container {
  width: 100%; }
  .strength-pdf-container object {
    width: 100%;
    height: 54em; }
    .strength-pdf-container object a {
      text-decoration: none; }
    .strength-pdf-container object a:hover {
      cursor: pointer;
      text-decoration: underline; }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .strength-pdf-container object {
    height: 44em; } }

@media screen and (max-width: 991px) {
  .strength-pdf-container object {
    height: 30em; } }
